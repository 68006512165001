
import type { CategoryAllResponse, CategoryAllRequestType, SubCategoriesResponse, SubCategoriesRequestType, CategoryBySlugRequestType, CategoryBySlugResponse, SimpleCategoriesResponce } from './types/CategoriesTypes';

export function useCategories() {
	const { $api } = useNuxtApp();

	const getCategoryAll = async (options?: CategoryAllRequestType) => {
		const res = await $api<CategoryAllResponse>('/category/all', {
			method: 'GET',
			params: {
				...options
			}
		});

		if (res) {
			return res;
		}
		return null;
	}

	const getCategoryBySlug = async (slug: string, options?: CategoryBySlugRequestType) => {
		const res = await $api<CategoryBySlugResponse>(`/category/slug/${slug}`, {
			method: 'GET',
			params: {
				...options
			}
		});

		if (res) {
			return res.category;
		}
		return null;
	}

	const getSubCategories = async (type: string, options?: SubCategoriesRequestType) => {
		const res = await $api<SubCategoriesResponse>(`/category/get-sub-categories/${type}`, {
			method: 'GET',
			params: {
				...options
			}
		});

		if (res) {
			return res;
		}
		return null;
	}

	const getSimpleCategories = async () => {
		const res = await $api<{categories: SimpleCategoriesResponce[]}>('/category/all-simple')
		
		if (res) {
			return res.categories
		}
		return null
	}

	const SimpleCategoriesSearch = async (searchPart: string) => {
		const res = await $api<{categories: SimpleCategoriesResponce[]}>(`/category/search/${searchPart}`)
		if (res) {
			return res.categories
		}
		return null
	}

	return {
		getCategoryAll,
		getSubCategories,
		getCategoryBySlug,
		getSimpleCategories,
		SimpleCategoriesSearch
	}
}
